import { useNavigate, useParams } from "react-router-dom";
import classes from "./CreateLesson.module.css";
import { useEffect, useRef, useState } from "react";
import BackIcon from "../../icons/BackIcon";

const CreateLesson = () => {
  const { lessonId } = useParams();


  let navigate = useNavigate();

  const iframeRef = useRef(null);
  const [containsTargetUrl, setContainsTargetUrl] = useState(false);

  

  const checkIframeUrl = () => {
    if (iframeRef.current) {
      try {
        // Access the iframe's current URL
        // const iframeUrl = iframeRef.current.contentWindow.location.href;

        console.log("ELEMENT1",iframeRef.current.contentWindow.location)

       

        // Check if the URL contains the target string
        // if (iframeUrl.includes("h5pc.thutotime.com/view")) {
        //   setContainsTargetUrl(true);
        //   console.log("URLS", iframeRef)
        // } else {
        //   setContainsTargetUrl(false);
        // }
      } catch (error) {
        console.error('Cross-origin access error:', error);
      }
    }
  };

  useEffect(() => {
    const frame = document.getElementById('frame')
    const isView = frame.src.includes("view")
    console.log("!!!!!!",frame.src, isView)
    
  });


  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.wrapper}>
      <div
        className={`d-flex align-items-baseline gap-3 mb-4 ${classes.backButton}`}
        onClick={handleGoBack}
        role="button"
      >
        <BackIcon />
        <h2>Back</h2>
      </div>
      <iframe
        ref={iframeRef}
        id="frame"
        src={`https://h5pc.thutotime.com/dashboard?title=${lessonId}`}
        title="Example Website"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default CreateLesson;
