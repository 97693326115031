import { useState } from 'react';
import classes from './InputField.module.css'
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const InputField = ({label, type, placeholder, labelBackground, onChange, value, name, children, showEye}) => {
    const [showPassword, setShowPassword] = useState(false)
    return(
        <div className={`${classes.inputWrapper}`}>
            <label style={{background: labelBackground}}>{label}</label>
            {!children && <div className='d-flex align-items-center gap-2'> <input type={showPassword ? "text" : type} placeholder={placeholder} onChange={onChange} value={value} name={name}/> {showEye && (showPassword ? <IoEyeOutline size={24} color="#737373" role="button" onClick={() => setShowPassword(false)}/> : <IoEyeOffOutline size={24} color="#737373" role="button" onClick={() => setShowPassword(true)}    />)}</div>}
            {children}
        </div>
    )
}

export default InputField