import classes from "./Dashboard.module.css";
import Accordion from "../UI/Accordion";
import InfoCard from "../UI/InfoCard";
import CalendarComponent from "./Components/CalendarComponent/CalendarComponent";
import { useEffect, useState } from "react";
import LessonDetailsModal from "../LessonDetailsModal/LessonDetailsModal";
import { useLessons } from "../../context/LessonsContext";
import LessonsScheduleCard from "../UI/LessonsScheduleCard/LessonsScheduleCard";

const resourcesData = [
  {
    title: "English Grammar Term 2",
  },
  {
    title: "English Grammar Term 3",
  },
];

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lessonDetails, setLesssonDetails] = useState({});

  const [lessonsToday, setLessonsToday] = useState([]);

  const { lessons } = useLessons();

  useEffect(() => {
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];

    // Filter Interactive Lessons that are for today
    const todaysLessons = lessons.filter(
      (lesson) => lesson.date_scheduled === today
    );

    console.log("Filter Interactive Lessons", todaysLessons);
    setLessonsToday(todaysLessons);
  }, []);

  const toggleLessonDetails = () => {
    setIsOpen(!isOpen);
  };
  const cards = [
    {
      title: "Average grade:",
      grade: "B+",
    },
    {
      title: "Attendance:",
      grade: "98%",
    },
  ];

  const dropList = [
    {
      title: "Lessons",
      updateList: [
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
      ],
    },
  ];

  return (
    <div className={`row ${classes.main}`}>
      <div className={`col-6 ${classes.calendarWrapper}`}>
        <CalendarComponent
          dashoard={true}
          toggleDetails={(event) => {
            setLesssonDetails(event);
            toggleLessonDetails();
          }}
        />
      </div>
      <div className={`col-6 ${classes.updateWrapper}`}>
        <div className="row m-0 gap-3 w-100 ">
          {cards.map((item, i) => (
            <InfoCard
              title={item.title}
              grade={item.grade}
              buttonText="View details"
            />
          ))}
        </div>
        {/* <Accordion dropList={dropList} isOpen={true} /> */}
        <LessonsScheduleCard lessonsList={lessonsToday} title="Lessons"/>
      </div>
      <LessonDetailsModal
        isOpen={isOpen}
        toggleDetails={toggleLessonDetails}
        lessonDetails={lessonDetails}
      />
    </div>
  );
};

export default Dashboard;
