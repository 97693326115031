import Cookies from "js-cookie";

// Function to set token
export const setToken = (token, expiresIn) => {
  Cookies.set("authToken", token, { expires: expiresIn / (24 * 60 * 60) }); // expiresIn should be in seconds
};

export const getDateTime = (date, time) => {
  const currentDate = new Date(date);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();
  const hours = splitTimeString(time).hours;
  const minutes = splitTimeString(time).minutes;

  return new Date(year, month, day, hours, minutes);
};

function splitTimeString(timeString) {
  if (!timeString) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  return { hours, minutes, seconds };
}

export function getOrdinal(number) {
  if (isNaN(+number)) {
    return number;
  }
  const j = number % 10;
  const k = Math.floor((number % 100) / 10);

  if (k === 1) {
    return number + "th"; // special case for 11th, 12th, 13th
  }

  if (j === 1) {
    return number + "st";
  }

  if (j === 2) {
    return number + "nd";
  }

  if (j === 3) {
    return number + "rd";
  }

  return number + "th";
}

export function calcDuration(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const diffInMs = endDate - startDate;

  const msInSecond = 1000;
  const msInMinute = msInSecond * 60;
  const msInHour = msInMinute * 60;
  const msInDay = msInHour * 24;

  const days = Math.floor(diffInMs / msInDay);
  const hours = Math.floor((diffInMs % msInDay) / msInHour);
  const minutes = Math.floor((diffInMs % msInHour) / msInMinute);
  const seconds = Math.floor((diffInMs % msInMinute) / msInSecond);

  return `${hours}h : ${minutes}min`;
}

export function formatedDateTime(dateToFormat) {
  const date = new Date(dateToFormat);

  const options = {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  let formattedDate = date.toLocaleString("en-GB", options);

  formattedDate = formattedDate.replace("am", "AM").replace("pm", "PM");

  return formattedDate;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0)?.toUpperCase() + string.slice(1);
}

export function formatLessonDate(startTime, endTime) {

  const today = new Date();

  const startDate = new Date(today.toDateString() + " " + startTime);
  const endDate = new Date(today.toDateString() + " " + endTime);

  const options = { weekday: "long", day: "numeric", month: "short" }; // e.g. "Tuesday 16 Feb"
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    startDate
  );

  const startTimeFormatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(startDate);

  const endTimeFormatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(endDate);

  return `${formattedDate} - ${startTimeFormatted} - ${endTimeFormatted}`;
}
