import { useEffect, useState } from 'react'
import { fetchCurriculum } from '../../services/curriculum'
import classes from './Curriculum.module.css'
import { useNavigate } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import CurriculumToolbar from './CurriculumToolbar/CurriculumToolbar'
import moment from 'moment';
import { capitalizeFirstLetter, getDateTime } from '../../utilities/functions'


const localizer = momentLocalizer(moment);


const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Set by",
        width: '150px'
    },
    {
        title: "Actions",
        width: '150px'
    } 
]

const tableContent = [
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 1
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 2
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 3
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 4
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 4
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 6
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 7
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 8
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 9
    }
    
]

const Curriculum = () => {

    let navigate = useNavigate();
    const [curriculums, setCurriculums] = useState([])


    const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();

    useEffect(() => {
        getCurriculum()
    },[])

    const getCurriculum = async() => {
        const result = await fetchCurriculum()
        console.log("Cuuuu", result)
        setCurriculums(result)
    }


    const transformedCurriculums = curriculums.map(curriculum => ({
        title: capitalizeFirstLetter(curriculum.term),
        lessonName: curriculum.term,
        lessonsType: curriculum.lesson_type,
        grade: curriculum?.grade,
        start: getDateTime(curriculum.start_date, "00:00"),
        end: getDateTime(curriculum.end_date, "23:00"),
        createdBy: curriculum.user_created?.first_name + " " + curriculum.user_created?.last_name,
        allDay: true,
      }));


    const events = [
        {
          title: 'Chemistry',
          start: new Date(2024, 6, day, 18, 0),
          end: new Date(2024, 6, day, 18, 30),
          allDay: false,
        },
        {
          title: 'Mathematical Literacy Term 1',
          start: new Date(2024, 5, day, 14, 0),
          end: new Date(2024, 5, day, 15, 0),
          allDay: false,
        },
        {
          title: 'Mathematical Literacy Term 3',
          start: new Date(2024, 5, day, 0, 0),
          end: new Date(2024, 5, day, 1, 0),
          allDay: false,
        },
      ];
    
      const [myEvents, setMyEvents] = useState(events);

    return(
        <div className={classes.main}>
            {/* <SearchBar placeholder="Search curriculum"/>
            <div className='mt-4'>
           <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                <tr>
                {tableHeader.map((item, i) => (
                    <th style={{width: item.width}} key={i}>{item.title}</th>
                ))}
                </tr>
                {tableContent.map((item, i) => (<tr>
                    <td><div className={tabeleClasses.removeButton} role='button'>{item.name}</div></td>
                    <td>{item.setBy}</td>
                    <td><div className='d-flex align-items-center justify-content-between'><div className={tabeleClasses.removeButton} role='button'>VIEW</div><div className={tabeleClasses.removeButton} role='button'>REMOVE</div></div></td>
                </tr>))}
                </table>
                </div> */}
                <div className="custom-calendar calendar-curriculum">
    <Calendar
      localizer={localizer}
      events={transformedCurriculums}
      // startAccessor="start"
      // endAccessor="end"
      // style={{ height: 500 }}
      views={['month']}  // Exclude 'agenda' from the views
      components={{
        toolbar: CurriculumToolbar,  // Use custom tool
      }}
    //   onSelectEvent={toggleDetails}
    />
  </div>
        </div>
    )
}

export default Curriculum