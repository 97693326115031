import { useState } from "react";
import SolidStarIcon from "../../icons/SolidStarIcon";
import { calcDuration, formatedDateTime } from "../../utilities/functions";
import CalendarLessonDetails from "../CalendarLessonDetails/CalendarLessonDetails";
import AboutLessonCard from "../UI/AboutLessonCard/AboutLessonCard";
import CardContainer from "../UI/CardContainer/CardContainer";
import CommonButton from "../UI/CommonButton/CommonButton";
import SectionTitleContainer from "../UI/SectionTitleContainer/SectionTitleContainer";
import classes from "./LessonDetailsModal.module.css";

const LessonDetailsModal = ({
  isOpen,
  toggleDetails,
  lessonDetails,
  onClick,
}) => {
  console.log("meeting ID", lessonDetails);
  const [isSending, setIsSending] = useState(false);

  const submitHander = async () => {
    if (lessonDetails.lessonsType === "Interactive Lesson") {
      return;
    }
    
    try {
      setIsSending(true);

      const fullName = lessonDetails?.createdBy?.replace(/ /g, "_");

      const bbbResponse = await fetch(`https://red.thutomdm.com/join`, {
        method: "POST",
        headers: {
          // 'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName,
          meetingID: lessonDetails.meetingID,
          password: lessonDetails.moderatorPW,
        }),
      });

      const result = await bbbResponse.json();

      window.open(
        `https://bbb.thutotime.com/bigbluebutton/api/join?fullName=${fullName}&meetingID=${lessonDetails.meetingID}&password=${lessonDetails.moderatorPW}&redirect=true&checksum=${result.checkSum}`,
        "_blank"
      );

      console.log("Response URL", result);
    } catch (err) {
      console.log("JOIN EERROR", err);
    }
    setIsSending(false);
  };
  return (
    <CalendarLessonDetails
      isOpen={isOpen}
      toggleDetails={toggleDetails}
      lessonName={lessonDetails.lessonName}
      lessonType={lessonDetails.lessonsType}
      subject={lessonDetails.title}
      teacher={lessonDetails.createdBy}
    >
      <div className={classes.lessonDetailsWrapper}>
        <SectionTitleContainer heading="About this lesson" className="mt-4" />
        <div className="row">
          <AboutLessonCard
            title="Type"
            value={
              lessonDetails.lessonsType === "Interactive Lesson"
                ? "Interactive"
                : "Live"
            }
          />
          <AboutLessonCard
            title="Duration"
            value={calcDuration(lessonDetails.start, lessonDetails.end)}
          />
          <AboutLessonCard
            title="Scheduled"
            value={formatedDateTime(lessonDetails.start)}
          />
        </div>

        <SectionTitleContainer
          heading="Resources for this lesson"
          className="mt-4"
          sort="Show: All types"
        />
        {/* {resourcesData.map((item, i) => (
            <CardContainer
              className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
            >
              <div>
                <div className={`mb-2 ${classes.resourcePill}`}>Resource</div>
                <h4>{item.title}</h4>
              </div>
              <SolidStarIcon />
            </CardContainer>
          ))} */}
        <CardContainer
          className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
        >
          <h4 style={{ color: "#959595" }}>
            You have no resources for this lesson
          </h4>
        </CardContainer>

        <SectionTitleContainer
          heading="Activity for this lesson"
          className="mt-4"
          sort="Show: All types"
        />
        <CardContainer
          className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
        >
          <h4 style={{ color: "#959595" }}>
            You have no activity for this lesson
          </h4>
        </CardContainer>
        {isSending ? (
          <div className="spinner-border" role="status"></div>
        ) : (
          <CommonButton
            onClick={submitHander}
            text={
              lessonDetails.lessonsType === "Interactive Lesson"
                ? "View"
                : "Join"
            }
          />
        )}
        {/* <a href={`https://bbb.thutotime.com/bigbluebutton/api/join?fullName=Teacher_One&meetingID=${lessonDetails.meetingID}&password=${lessonDetails.moderatorPW}&redirect=false&checksum=e0a0ff683c31ed103229e4793d3254b5ca9da07e`} target='_blank'>Join</a> */}
      </div>
    </CalendarLessonDetails>
  );
};

export default LessonDetailsModal;
