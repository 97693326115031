import { useState } from 'react'
import classes from './LessonsScheduleCard.module.css'
import BoardIcon from '../../../icons/BoardIcon'
import { formatLessonDate, getDateTime, getOrdinal } from '../../../utilities/functions'
import VideoIcon from '../../../icons/VideoIcon'


const LessonsScheduleCard = ({lessonsList, title}) => {
    console.log("WHAT ARE WE GETTING HERE", lessonsList)
    return(
      <div className={`${classes.updateListCard} mb-3`}>
        <h4 className='mb-3' style={{fontSize: 14}}>{title}</h4>
                            <div className='d-flex align-items-center justify-content-between mb-4'>
                                <h4>Today</h4>
                                {/* <div className={classes.showType}>Show: All types</div> */}
                            </div>
                            {lessonsList.length > 0 ? <>
                            {lessonsList.map((lesson, i) => {
                                const formatted = formatLessonDate(lesson.start_time, lesson.end_time);
                                return (<div className={`${classes.updateContainer} mb-3`} key={i}>
                                <div className='d-flex align-items-center gap-3'>
                                <div className={`${classes.capsule} d-flex align-items-center gap-2`} style={{backgroundColor: lesson.lesson_type === "Live Lesson" && "#E3F0E7"}}> {lesson.lesson_type === "Interactive Lesson" ? <BoardIcon size="small"/> : <VideoIcon size="small"/>}<span style={{color: lesson.lesson_type === "Live Lesson" && "#43B061"}}>{lesson.lesson_type}</span></div><h5>{getOrdinal(lesson.class?.grade)} Grade</h5>
                                </div>
                                <div className='d-flex flex-column align-items-start mt-2'>
                                <h4>{lesson.subject}: {lesson.lesson_name} </h4>
                                <h5>{formatted}</h5>
                                </div>
                            </div>)})}
                            
                            <div className={`${classes.viewLessonsButton}`} role='button'>
                                View lessons
                            </div>
                            </> :
                            <p style={{fontSize: 14}}>No {title} found</p>}
                        </div>
                 
         
    )
}

export default LessonsScheduleCard