import classes from './CommonButton.module.css'

const CommonButton = ({onClick, text, className}) => {
    return(
       <div className={`${className} text-center ${classes.buttonWrapper}`} onClick={onClick} >
            {text}
        </div>
    )
}

export default CommonButton