import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Dashboard from "./Components/Dashboard/Dashboard";
import Lessons from "./Components/Lessons/Lessons";
import Calendar from "./Components/Calendar/Calendar";
import Classes from "./Components/Classes/Classes";
import Learners from "./Components/Learners/Learners";
import Assessments from "./Components/Assessments/Assessments";
import RequestAssistance from "./Components/RequestAssistance/RequestAssistance";
import Curriculum from "./Components/Curriculum/Curriculum";
import Performance from "./Components/Performance/Performance";
import ResourceLibrary from "./Components/ResourceLibrary/ResourceLibrary";
import LessonDetails from "./Components/Lessons/LessonDetails/LessonDetails";
import LiveLesson from "./Components/Lessons/LessonDetails/LiveLesson/LiveLesson";
import Grade from "./Components/Classes/Grade/Grade";
import Learner from "./Components/Classes/Grade/Learner/Learner";
import Resource from "./Components/ResourceLibrary/Resource/Resource";
import HelpCategory from "./Components/RequestAssistance/HelpCategory/HelpCategory";
import Article from "./Components/RequestAssistance/Article/Article";
import MyAccount from "./Components/MyAccount/MyAccount";
import ChangeInformation from "./Components/MyAccount/ChangeInformation/ChangeInformation";
import ChangePIN from "./Components/MyAccount/ChangePIN/ChangePIN";
import CopyrightNotice from "./Components/MyAccount/CopyrightNotice/CopyrightNotice";
import TermsAndConditions from "./Components/MyAccount/TermsAndConditions/TermsAndConditions";
import Login from "./Components/Login/Login";
import { AuthProvider } from "./context/AuthContext.js";
import { LessonsProvider } from "./context/LessonsContext.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoute.js";
import { SchoolsProvider } from "./context/SchoolsContext.js";
import { ClassesProvider } from "./context/ClassesContext.js";
import { refreshAccessToken } from "./services/auth.js";
import { useEffect } from "react";
import { setToken } from "./utilities/functions.js";
import CreateLesson from "./Components/CreateLesson/CreateLesson.js";

const TOKEN_REFRESH_INTERVAL = 3 * 60 * 1000; // 5 minutes in milliseconds

function App() {



    // useEffect(() => {
  //   // Function to refresh the token
  //   const refreshToken = async () => {
  //     try {
  //       const result = await refreshAccessToken()

  //       setToken(result.access_token, result.expires)
  //     } catch (error) {
  //       console.error('Error refreshing token:', error);
  //     }
  //   };

  //   // Call the refresh token function every 5 minutes
  //   const intervalId = setInterval(refreshToken, TOKEN_REFRESH_INTERVAL);

  //   // Call refresh token immediately on component mount
  //   refreshToken();

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      <ToastContainer />
        <BrowserRouter>
          <AuthProvider>
            <LessonsProvider>
              <SchoolsProvider>
                <ClassesProvider>
                  <Routes>
                    <Route path="/" element={<PrivateRoute />}>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/lessons" element={<Lessons />} />
                        <Route
                          path="/lessons/details/:id"
                          element={<LessonDetails />}
                        />
                        <Route
                          path="/lessons/live-lesson/:lessonId"
                          element={<LiveLesson />}
                        />
                        <Route
                          path="/lessons/create-lesson/:lessonId"
                          element={<CreateLesson />}
                        />
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/performance" element={<Performance />} />
                        <Route path="/assessments" element={<Assessments />} />
                        <Route path="/classes" element={<Classes />} />
                        <Route
                          path="/classes/grade/:gradeId"
                          element={<Grade />}
                        />
                        <Route
                          path="/learner/:learnerId"
                          element={<Learner />}
                        />
                        <Route path="/learners" element={<Learners />} />
                        <Route
                          path="/resource-library"
                          element={<ResourceLibrary />}
                        />
                        <Route
                          path="/resource-library/:resourceId"
                          element={<Resource />}
                        />
                        <Route path="/curriculum" element={<Curriculum />} />
                        <Route
                          path="/request-assistance"
                          element={<RequestAssistance />}
                        />
                        <Route
                          path="/request-assistance/:categoryId"
                          element={<HelpCategory />}
                        />
                        <Route
                          path="/request-assistance/:categoryId/:articleId"
                          element={<Article />}
                        />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route
                          path="/my-account/change-my-information"
                          element={<ChangeInformation />}
                        />
                        <Route
                          path="/my-account/change-my-pin"
                          element={<ChangePIN />}
                        />
                        <Route
                          path="/my-account/copyright-notice"
                          element={<CopyrightNotice />}
                        />
                        <Route
                          path="/my-account/terms-and-conditions"
                          element={<TermsAndConditions />}
                        />
                      </Route>
                    </Route>
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </ClassesProvider>
              </SchoolsProvider>
            </LessonsProvider>
          </AuthProvider>
        </BrowserRouter>
    </>
  );
}

export default App;
