// src/MyCalendar.js
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import classes from './CalendarComponent.module.css';  // Custom CSS file
import CustomToolbar from './Components/CustomToolbar/CustomToolbar';
import DayViewCustomEvent from './Components/CustomEvent/DayViewCustomEvent';
import CustomTimeSlot from './Components/CustomTimeSlot/CustomTimeSlot';
import CustomToolbarComponent from './Components/CustomToolbarComponent/CustomToolbarComponent';

import { getDateTime } from '../../../../utilities/functions.js';
import { useLessons } from '../../../../context/LessonsContext.js';


const localizer = momentLocalizer(moment);



const CalendarComponent = ({dashoard, toggleDetails}) => {
  
  const { lessons } = useLessons()
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();

  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    // Do something with the event
    toggleDetails(event)
  };

  console.log('Event clicked:', lessons);


  const transformedLessons = lessons.map(lesson => ({
    title: lesson.subject,
    lessonName: lesson.lesson_name,
    lessonsType: lesson.lesson_type,
    grade: lesson.class?.grade,
    start: getDateTime(lesson.date_scheduled, lesson.start_time),
    end: getDateTime(lesson.date_scheduled, lesson.end_time),
    createdBy: lesson.user_created?.first_name + " " + lesson.user_created?.last_name,
    allDay: false,
    meetingID: lesson.stream_id,
    moderatorPW: lesson.mp
  }));




  const events = [
    {
      title: 'Chemistry',
      start: new Date(2024, 6, day, 18, 0),
      end: new Date(2024, 6, day, 18, 30),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 1',
      start: new Date(2024, 5, day, 14, 0),
      end: new Date(2024, 5, day, 15, 0),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 3',
      start: new Date(2024, 5, day, 0, 0),
      end: new Date(2024, 5, day, 1, 0),
      allDay: false,
    },
  ];

  const [myEvents, setMyEvents] = useState(events);



  return (
    <div className="custom-calendar">
    <Calendar
      localizer={localizer}
      events={transformedLessons}
      // startAccessor="start"
      // endAccessor="end"
      // style={{ height: 500 }}
      views={['month', 'week', 'day']}  // Exclude 'agenda' from the views
      components={{
        toolbar: dashoard ? CustomToolbarComponent : CustomToolbar ,  // Use custom toolbar
        day: {
          event: DayViewCustomEvent  // Use custom event component for day view only
        },
      }}
      onSelectEvent={toggleDetails}
    />
  </div>
  );
};

export default CalendarComponent